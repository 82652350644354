#slide-2 {
  background-color: #1d1d1d;
}

.container {
  width: 600%;
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
}

/*.horz_scroll {
  width: 500%;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
}*/

section {
  width: 100vw;
  height: 100vh;
}

.horz_panel {
  position: inherit;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4ch;
  border-radius: 35px;
}

p{
  text-align: justify;
}

.text-zone {
  position: relative;
  display: inline-block;
  left: 0;
  width: 40%;
  top: 0;
  margin-top: -5%;
  margin-left: -5%;
  height: 90%;
  max-height: 600px;
  font-size: 0.47em;
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 5%;
  background-color: #1d1d1d;
  color: whitesmoke;
  border-radius: 15px;
}
.skills-details {
  position: relative;
  display: inline-block;
  width: 35%;
  height: 90%;
  max-height: 600px;
  padding-left: 1%;
  padding-top: 1%;
  padding-bottom: 5%;
  background-color: #1d1d1d;
  border-radius: 15px;
}

.skills-text {
  padding: 0;
  margin-top: 0;
  font-size: 15px;
  color: whitesmoke;
  text-align: justify;
}
.sk-card {
  height: 60px;
  width: 98%;
  border-radius: 0;
  background-color: inherit;
}
.sk-body {
  height: 30px;
  font-size: 10px;
}
.sk-chart-bg{
    width:100%;
    height:3px;
    background-color:lightslategray;
    float:left;
}
.sk-chart{
    height:3px;
    
    float:left;
}
#sk-ln-1 {
  width: 85%;
  background-color:#08fdd8;
}
#sk-ln-2 {
  width: 75%;
  background-color:#ff22fd;
}
#sk-ln-3 {
  width: 95%;
  background-color: #09fd00;
}
#sk-ln-4 {
  width: 75%;
  background-color:#08fdd8;
}
#sk-ln-5 {
  width: 85%;
  background-color:#ff22fd;
}
#sk-ln-6 {
  width: 60%;
  background-color: #09fd00;
}
#sk-ln-7 {
  width: 60%;
  background-color:#08fdd8;
}
#sk-ln-8 {
  width: 80%;
  background-color:#ff22fd;
}
#sk-ln-9 {
  width: 85%;
  background-color: #09fd00;
}

@media only screen and (max-width: 850px) {
  /*.panel {
    overflow-y: scroll;
  }*/
  .horz_panel {
    height: 200vh;
  }
  #slide-2 {
    height: 200vh;
  }

  .text-zone {
    position: absolute;
    display: inline-block;
    left: 0;
    width: 90%;
    margin-left: 5%;
    margin-right: 0;
    top: 2%;
    height: 90%;
    font-size: 0.50em;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 5%;
  }
  .skills-details {
    position: absolute;
    display: inline-block;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 0;
    margin-left: 5%;
    padding-left: 1%;
    padding-top: 1%;
    padding-bottom: 5%;
    background-color: #1d1d1d;
    border-radius: 15px;
  }
}
