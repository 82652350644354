#slide-3 {
  background-color: whitesmoke;
}

/*.pro_holder_3 {
  position: absolute;
  display: flex;
  top: 25%;
  justify-content: space-around;
  align-content: space-around;
  width: 100%;
  height: 100%;
  z-index: 10;
}*/

#job_img_3 {
  z-index: 12;
  position: absolute;
  width: 98vw;
  top: 8vh;
  left: 1vw;
}

#k_header {
  position: absolute;
  left: 45vw;
  top: 15vh;
  font-size: 1.5em;
  font-family: monospace;
  font-weight: bold;
  color: slategrey;
}

.kcard {
  height: 60vh;
  width: 40vw;
  background-color: transparent;
  color: #1d1d1d;
}

#kh {
  color: #1d1d1d;
  background-color: whitesmoke;
}

h4 {
  margin: 8px;
}

#ksci_fs {
  width: 40vw;
  height: 60vh;
  left: 55vw;
  top: 30vh;
  position: absolute;
  font-family: monospace;
}

#k_python {
  width: 40vw;
  height: 40vh;
  left: 2vw;
  top: 50vh;
  position: absolute;
  font-family: monospace;
}

.pro_head {
  color: blue;
  font-size: 1.2em;
  font-weight: bold;
  font-family: monospace;
}

@media only screen and (max-width: 850px) {
  #slide-3 {
    height: 160vh;
  }

  #ksci_bg_img {
    display: block;
    position: absolute;
    width: 80vw;
    left:  10vw;
    top: 0;
  } 

  #bg_3_img {
    display: none;
  }

  #k_header {
    position: absolute;
    left: 5vw;
    top: 30vh;
    font-size: 1.5em;
    font-family: monospace;
    font-weight: bold;
    color: slategrey;
    width: 90vw;
  }

  #ksci_fs {
    width: 90vw;
    height: 40vh;
    left: 5vw;
    top: 40vh;
    position: absolute;
    font-family: monospace;
  }

  #k_python {
    width: 90vw;
    height: 40vh;
    left: 5vw;
    top: 95vh;
    position: absolute;
    font-family: monospace;
  }

  #bg{
    width: auto;
    height: 120vh;
  }
}