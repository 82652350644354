html, body { height: auto; }

.panel {
  height: 100vh;
  position: sticky;
  overflow-x: hidden;
  overflow-y: hidden;
}

/*.slideobj {
  height: 100vh;
  width: 100vw;
}*/

/*.page{
  position: relative;
  z-index: 1;
}*/