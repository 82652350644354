.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  color: #7c7d7e;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #27255b;
}


.preloader[data-v-62763bce] {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  z-index: 20;
  background: none;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh)*100);
  cursor: wait;
}

h1 {
  text-align: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  border-radius: 1px;
  border-width: 0px;
  height: 40vh;
  width: 27vw;
  border-color: #727d89;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  font-weight: bold;
  font-size: 1em;
  padding:  10px 10px 10px 10px;
  margin: 5px;
  background-color: #1d1d1d;
  color: white;
}

.card-header {
  padding: 0px 4px;
  background-color: #1d1d1d;
  border-color: #000000;
  border-width: 2px;
  border-left-color: #000000;
  border-left-width: 10px;
}

.card-img {
  min-height: 100px;
}