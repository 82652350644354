.v-text {
  overflow: hidden;
  width: 45%;
  min-width: 775px;
  min-height: 700px;
  height: 500px;
  padding: 0px 0 0px;
  position: relative;
  background-color: none;
  left: 35%;
  top: -70%;
  font-size: 2.5rem;
  font-family: 'Montserrat', Arial;
  transform: rotate(-90deg);
  z-index: 5;
}

.looper {
  overflow: hidden;
  width: 40%;
  height: 100%;
  padding: 0px 0 0px;
  position: fixed;
  bottom: -50px;
  left: 50%;
  font-size: 5.5rem;
  font-family: 'Montserrat', Arial;
  transform: rotate(-90deg);
}

.looper-one {
  margin-left: 0px;
  padding-bottom: 0px;
  color: #000000;
  font-size: 20px;
  text-transform: uppercase;
  text-shadow: 0 0 2px #065b70;
  background: linear-gradient(135deg,  rgba(74,255,255,0) 0%,rgba(58,208,221,0) 11%,rgba(38,153,181,0) 24%,rgba(29,127,162,0) 30%,rgba(29,127,162,0) 68%); /* W3C */
}

.looper-one > * {
  display: inline-block;
  margin-right: 5px;
  /*animation: vt-one 6s infinite linear;*/
}

@keyframes vt-one {
  from {
    transform: rotate(-90deg) translateX(300%);
  }
  to {
    transform: rotate(-90deg) translateX(-600%);
  }
}

.looper-two {
  margin-left: 5%;
  color: #000000;
  font-size: 36px;
  text-transform: uppercase;
  text-shadow: 0 0 5px rgba(0,0,0,1); 
  background: linear-gradient(135deg,  rgba(74,255,255,0) 0%,rgba(29,127,162,0) 11%,rgba(29,127,162,0) 0%);
}

.looper-two > * {
  display: inline-block;
  /*animation: vt-two 6s infinite linear;*/
}

@keyframes vt-two {
  from {
    transform: rotate(-90deg) translateX(300%);
  }
  to {
    transform: rotate(-90deg) translateX(-600%);
  }
}

.looper-three {
  margin-left: 25%;
  color: #000000;
  font-size: 36px;
  text-transform: uppercase;
  text-shadow: 0 0 5px rgba(0,0,0,1); 
  background: linear-gradient(135deg,  rgba(74,255,255,0) 0%,rgba(29,127,162,0) 11%,rgba(29,127,162,0) 0%);
}

.looper-three > * {
  display: inline-block;
  /*animation: vt-three 5s infinite linear;*/
}

@keyframes vt-three {
  from {
    transform: rotate(-90deg) translateX(300%);
  }
  to {
    transform: rotate(-90deg) translateX(-200%);
  }
}

@keyframes slideAnimation {
  from {
    transform: rotate(-90deg) translateX(300%);
    /*transform: rotate(-90deg);*/
  }
  to {
    transform: rotate(-90deg) translateX(-50%);
    /*transform: rotate(-90deg);*/
  }
}
@keyframes textAnimation {
  from {
    transform: rotate(-90deg) translateX(275%);
    /*transform: rotate(-90deg);*/
  }
  to {
    transform: rotate(-90deg) translateX(-100%);
    /*transform: rotate(-90deg);*/
  }
}

.s-text {
  font-size: 5.5rem;
  text-align: left;
  color: whitesmoke;
}

@keyframes bounce {
  0%, 100%, 20%, 50%, 80% {
    transform: translateX(0);
    /*transform: translateX(0)*/
  }
  40% {
    /*-webkit-transform: translateX(-30px);
    -ms-transform:     translateX(-30px);*/
    transform:         translateX(-30px)
  }
  60% {
    /*-webkit-transform: translateX(-15px);
    -ms-transform:     translateX(-15px);*/
    transform:         translateX(-15px)
  }
}

.s-text {  
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.s-text:hover {
  cursor: pointer;
  font-size: 6.2rem;
  color: lightgray;
  transform: rotate(45deg);
  animation-name: bounce;
}


