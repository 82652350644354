#slide-4 {
  background-color: #1d1d1d;
  width:  100vw;
  height: 100vh;
}

.pro-holder {
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.card {
  z-index: 10;
}

.exp-card {
  width: 40%;
  height: 80%;
  font-size: .8em;
  opacity: 0.8;
}

.job-card {
  align-items: center;
  justify-content: center;
}

h4 {
  text-align: center;
  justify-content: center;
}

h2 {
  text-align: center;
  justify-content: center;
}

.img_card {
  width: 80vw;
  height: 80vw;
  left: 10%;
  top: 10%;
  position: fixed;
  background-color: none;
}

#inlay {
  width: 100vw;
  height: 80vh;
  position: absolute;
  left: 0vw;
  top: 15vh;
  background-color: whitesmoke;
}

#job_img_1 {
  z-index: 2;
  position: absolute;
  top: 15%;
  left: -10%;
  transform: rotate(-45deg);
}
#job_img_1:hover {
  z-index: 12;
}
#faro_bg_img {
  
  transform: scale(0.7);
}

.pastbg:hover{
  z-index: 15;
}

#job_img_2 {
  z-index: 2;
  position: absolute;
  top: 0%;
  left: 45%;
  transform: scale(0.7);
}
#job_img_2:hover {
  z-index: 12;
}

@media only screen and (max-width: 850px) {
  #slide-4 {
    height: 150vh;
  }

  #inlay {
    height: 130vh;
  }

  .pro-holder {
    display: inline-grid;
    justify-content: none;
    align-content: none;
  }

  .exp-card {
    width: 80vw;
    height: 60vh;
    font-size: medium;
    font-family: monospace;
    opacity: 1.0;
    background-color: #1d1d1d;
    color: whitesmoke;
/*    justify-content: end;*/
  }

  .ch4 {
    height: 20%;
  }

  #job_img_1 {
    display: none;
  }
  #job_img_2 {
    display: none;
  }

  .card-header {
    background-color: whitesmoke;
    color: #1d1d1d;
  }
}
