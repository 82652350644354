#slide-0 {
  position: fixed;
  background-color: #1d1d1d;
  width: 100vw;
  height: 100vh;
}


#logo {
  transform: scale(0.8);
}

#txt_holder {
  position: absolute;
  width: 100vw;
  height: 80vh;
  top: 20vh;
  background-color: none;
}

#dtag {
  padding-left: 20px;
  color: whitesmoke;
}

#ctag {
  padding-left: 40px;
  color: whitesmoke;
}

#itag {
  padding-left: 60px;
  color: whitesmoke;
}

#s-0-h {
  position: relative;
  top: 38%;
  left: 5%;
  height: 50%;
  width: 30%;
  margin-bottom: 0;
  background-color: none;
}


#s-0-vert-text {
  position: relative;
  margin-left: 40vw;
  top: 99vh;
  width: 40vw;
  height: 250vw;
  font-size: 3.5rem;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  white-space: nowrap;
  background-color: none;
  opacity: 0.75;
  filter: blur(5px);
  color: whitesmoke;

}
#s-1-vert-text {
  position: relative;
  margin-left: 70vw;
  top: 99vh;
  width: 40vw;
  height: 250vw;
  font-size: 3rem;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.hcard {
  width: 100vw;
  min-width: 400px;
  height: 100vw;
  min-height: 400px;
  /*background-color: #dedede;*/
}

#splash_vid_0{
  position: absolute;
  width: 45vw;
  height: 100vh;
  left:  40%;
}

#bg_stl_0 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 8;
}



#s0_bg_img {
  position: absolute;
  width: 50vw;
  height: 50vw;
  z-index: 2;
  top: 0vh;
  left: 50vw;
  transform: rotate(180deg);
}

@media only screen and (max-width: 850px) {
  .v-text{
    display: none;
  }

  .panel {
    height: 250vh;
  }

  #s-0-h {
    position: absolute;
    top: 30%;
    margin-bottom: 0;
    background-color: #1d1d1d;
  }

  .slideobj {
    align-content: center;
    justify-content: center;
  }
  #dtag {
    padding-left: 0px;
  }

  #ctag {
    padding-left: 0px;
  }

  #itag {
    padding-left: 0px;
  }

  #logo {
    width:  100vw;
  }
}